<template>
  <div class="services__form" id="contact">
    <div class="services__formRow">
      <div class="services__formRowTitle orbitron">Contact us</div>
    </div>
    <div v-if="getMobile" class="services__formRow">
      <div class="services__formDescription spartan">Send your question to us, we will contact you soon.</div>
    </div>
    <div class="services__formRow">
      <div class="services__formRowInner">
        <div class="services__formCol services__formColFirst">
          <Input placeholder="Name" v-model="name"/>
          <Input placeholder="E-mail" v-model="email"/>
        </div>
        <div class="services__formCol services__formColSecond">
          <TextArea placeholder="Message" v-model="message"/>
        </div>
        <div class="services__formCol services__formColThree">
          <div v-if="!getMobile" class="services__formDescription spartan">Send your question to us, we will contact you soon.</div>
          <Button value="Contact us" @click="sendForm()"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Input from "@/components/Atoms/Input";
import TextArea from "@/components/Atoms/TextArea";
import Button from "@/components/Atoms/Button";
import {mapGetters} from "vuex";
export default {
  name: "ServicesForm",
  components: {Button, TextArea, Input},

   data() {
      return {
        name: null,
        email: null,
        message: null
      }
   },

  computed: {
    ...mapGetters(['getMobile'])
  },

  methods: {
    sendForm() {
      console.log('123')
      this.name = null,
      this.email = null,
      this.message = null
    }
  },
}
</script>

<style scoped lang="scss">
  .services {

    &__form {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      position: relative;

      @media (max-width: 1024px) {
        position: absolute;
        width: 100%;
        height: 100%;
        position: relative;
        background: linear-gradient(180deg, #AA14F0 -42.59%, rgba(0, 0, 0, 0) -42.59%, #AA14F0 -42.58%, rgba(170, 20, 240, 0.18) 0%, rgba(185, 54, 245, 0) 100%);
        background-size: cover;
        margin-bottom: 50px;
        padding: 0 15px;
      }
    }

    &__formRow {
      display: flex;
      align-items: center;
      width: 100%;

      @media (max-width: 1024px) {
        flex-direction: column;
      }
    }

    &__formRowTitle {
      font-weight: 900;
      font-size: 36px;
      line-height: 70px;
      color: #FFFFFF;

      @media (max-width: 1024px) {
        margin-top: 40px;
      }

      @media (max-width: 767px) {
        font-size: 24px;
        line-height: 30px;
      }
    }

    &__formRowInner {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      width: 100%;
      height: 131px;

      @media (max-width: 1024px) {
        flex-direction: column;
        height: auto;
      }
    }

    &__formCol {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
    }

    &__formDescription {
      font-weight: 500;
      font-size: 18px;
      line-height: 28px;
      color: #FFFFFF;

      @media (max-width: 1024px) {
        margin-bottom: 20px;
        font-size: 18px;
        line-height: 28px;
      }
    }

    &__formColFirst {
      width: 20%;

      @media (max-width: 1024px) {
        width: 100%;
      }
    }

    &__formColSecond {
      width: 55%;

      @media (max-width: 1024px) {
        width: 100%;
        margin-bottom: 10px;
      }
    }

    &__formColThree {
      width: 20%;

      @media (max-width: 1024px) {
        width: 100%;
      }
    }
  }
</style>