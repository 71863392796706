<template>
  <div class="services" id="services">
    <div class="container">
      <div class="services__title orbitron">Services</div>
      <div class="container__inner">
        <div class="services__list">
          <div class="services__item">
            <div class="services__itemInner">
              <div class="services__itemInnerImage visual__effects">
                <img src="../assets/services_1.png">
              </div>
              <div class="services__itemInnerTitle orbitron">Visual effects & animation</div>
              <div class="services__itemInnerText spartan">Text text text text text text text text text text text text text text text text text text text text text text text text text text text text.</div>
            </div>
          </div>
          <div class="services__item">
            <div class="services__itemInner">
              <div class="services__itemInnerImage brands__integration">
                <img src="../assets/services_2.png">
              </div>
              <div class="services__itemInnerTitle orbitron">Brands integration</div>
              <div class="services__itemInnerText spartan">Offering brands contextually relevant virtual placement opportunities</div>
            </div>
          </div>
          <div class="services__item">
            <div class="services__itemInner">
              <div class="services__itemInnerImage motion__design">
                <img src="../assets/services_3.png">
              </div>
              <div class="services__itemInnerTitle orbitron">Motion design</div>
              <div class="services__itemInnerText spartan">Text text text text text text text text text text text text text text text text text text text text text text text text text text text text.</div>
            </div>
          </div>
        </div>
      </div>
      <ServicesForm />
    </div>
  </div>
</template>

<script>
import ServicesForm from "@/components/Services/ServicesForm";
export default {
  name: "Services",
  components: {ServicesForm}
}
</script>

<style scoped lang="scss">
  .container {
    flex-direction: column;

    @media (max-width: 1024px) {
      padding: 0;
    }
  }

  .container__inner {
    max-width: 1240px;
    margin: 0 auto;

    @media (max-width: 1024px) {
      padding: 0 15px;
    }
  }

  .services {
    width: 100%;
    position: relative;
    background-image: url("../assets/services-bg.png");
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 100px;

    @media (max-width: 1024px) {
      margin-bottom: 0;
    }

    &__title {
      font-weight: 900;
      font-size: 36px;
      line-height: 70px;
      text-align: center;
      color: #FFFFFF;
      margin-top: 80px;
      margin-bottom: 60px;

      @media (max-width: 1280px) {
        margin-top: 40px;
        margin-bottom: 20px;
      }

      @media (max-width: 767px) {
        font-size: 24px;
        line-height: 30px;
      }
    }

    &__list {
      display: flex;
      align-items: flex-start;
      justify-content: space-around;
      margin-bottom: 50px;

      @media (max-width: 1024px) {
        flex-direction: column;
      }

      @media (max-width: 767px) {
        margin-bottom: 20px;
      }
    }

    &__item {
      width: 27%;

      @media (max-width: 1280px) {
        width: 24%;
      }

      @media (max-width: 1024px) {
        width: 100%;
        margin-bottom: 45px;
      }

      &:hover {
        .services__itemInnerTitle {
          color: transparent;
          background-clip: text;
          background-image: linear-gradient(99.05deg, #C824EC 0.07%, #DF308D 106.32%);
        }

        .services__itemInnerImage {
          transform: scale(1.05);
        }

        .services__itemInnerImage {

          img {
            transform: scale(1.05);
          }
        }
      }
    }

    &__itemInner {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
    }

    &__itemInnerImage {
      min-height: 400px;
      width: 100%;
      height: 100%;
      position: relative;
      border-radius: 10px;
      display: flex;
      align-items: flex-end;
      justify-content: center;

      @media (max-width: 1280px) {
        min-height: 320px;
      }

      @media (max-width: 767px) {
        min-height: 250px;
        align-items: center;
        justify-content: center;
      }

      img {
        width: auto;
        height: auto;
        position: absolute;

        @media (max-width: 1280px) {
          transform: scale(0.8);
        }

        @media (max-width: 767px) {
          transform: scale(0.7);
        }
      }
    }

    &__itemInnerTitle {
      font-weight: 900;
      font-size: 36px;
      line-height: 46px;
      color: #FFFFFF;
      margin-top: 30px;
      min-height: 92px;

      @media (max-width: 1024px) {
        min-height: auto;
      }

      @media (max-width: 767px) {
        font-size: 24px;
        line-height: 30px;
      }
    }

    &__itemInnerText {
      font-weight: 500;
      font-size: 18px;
      line-height: 28px;
      color: #FFFFFF;
      margin-top: 19px;
    }
  }

  .visual__effects {
    background-color: #376166;
  }

  .brands__integration {
    background-color: #2E2F34;
  }

  .motion__design {
    background-color: #6B5078;
  }
</style>