<template>
  <div class="slider">
    <div class="slider__track" :key="track">
      <div
          v-for="(item, index) in getSlider"
          :key="index"
          class="slider__item"
      >
        <video width="100%" height="100%" controls="controls" :poster="'https://vfx.protarget.pro/img/' + item.image">
          <source :src="'https://vfx.protarget.pro/video/' + item.video" type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"'>
        </video>
      </div>
    </div>
    <div class="slider__pagination">
      <div
          v-for="i of getSliderPagination"
          :id="i"
          :key="i"
          :class="{ 'active': getSliderActive === i - 1 }"
          class="slider__dots"
          @click="setSlider(i)"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'Slider',
  data () {
    return {
      interval: null,
      track: 0
    }
  },

  computed: {
    ...mapGetters([ 'getSlider', 'getSliderPagination', 'getSliderActive']),
  },

  methods: {
    ...mapActions(['setSliderActive', 'setNextPageSlider']),

    setSlider(i) {
      this.setSliderActive(i)
      this.track ++
    }

    // timer () {
    //   this.interval = setInterval(() => {
    //     this.setNextPageSlider()
    //   }, 4000)
    // },
  },

  mounted() {
    // this.timer();
  },

  beforeDestroy () {
    clearInterval(this.interval)
  },
}
</script>

<style scoped lang="scss">
.slider {
  z-index: 555;
  width: 100%;
  max-width: 1024px;
  margin: 0 auto;

  &__track {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    z-index: 5;
  }

  &__item {
    width: 100%;
    max-height: 600px;
    margin-right: 3%;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    -webkit-box-shadow: 1px -1px 10px 9px rgba(197, 74, 146, 0.5);
    -moz-box-shadow: 1px -1px 10px 9px rgba(197, 74, 146, 0.5);
    box-shadow: 1px -1px 10px 9px rgba(197, 74, 146, 0.5);
    cursor: pointer;

    &:last-child {
      margin-right: 0
    }

    @media (min-width: 1921px) {
      min-height: 200px;
      max-height: 200px;
    }

    &:after {
      content: "";
      bottom: 30px;
      right: 45px;
      background-color: #a1346d;
      position: absolute;
      width: 100%;
      height: 150px;
      border-radius: 4px;
      z-index: -1;

      @media (min-width: 1921px) {
        height: 200px;
      }
    }

    img {
      width: 100%;
      height: 100%;
    }
  }

  &__text {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 26px;
    line-height: 28px;
    text-align: center;
    color: #FFFFFF;
    margin-top: 140px;
  }

  &__pagination {
    display: flex;
    justify-content: center;
    margin-top: 85px;

    @media (max-width: 767px) {
      margin-top: 20px;
    }
  }

  &__dots {
    width: 12px;
    height: 12px;
    border-radius: 50px;
    background: #FFFFFF;
    opacity: 0.2;
    margin: 0 6px;
    cursor: pointer;
  }
}

.active {
  opacity: 1;
}
</style>
