<template>
  <div class="footer spartan">
    <div class="container">
      <div class="footer__col">
        <div class="footer__logo">
          <a href="/">
            <img src="../assets/logo.png">
          </a>
        </div>
      </div>
      <div v-if="!getMobile" class="footer__col">
        <div class="footer__nav">
          <div class="footer__navTitle">Links</div>
          <div class="footer__navList">
            <ul>
              <li>
                <a class="footer__navItem" href="#about">About</a>
              </li>
              <li>
                <a class="footer__navItem" href="#services">Services</a>
              </li>
              <li>
                <a class="footer__navItem" href="#contact">Contact</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="footer__col">
        <div class="footer__contacts">
          <div v-if="!getMobile" class="footer__contactsTitle">Contacts</div>
          <div class="footer__contactsText">
            604.742.0067 <br>
            info@atmosphere-vfx.com <br>
            300-177 West 7th Avenue <br>
            Vancouver, BC, Canada <br>
            V5Y 1L8
          </div>
        </div>
      </div>
      <div class="footer__col">
        <div class="footer__social">
          <a href="#" target="_blank" class="footer__socialItem twitter"></a>
          <a href="#" target="_blank" class="footer__socialItem facebook"></a>
          <a href="#" target="_blank" class="footer__socialItem pinterest"></a>
          <a href="#" target="_blank" class="footer__socialItem instagram"></a>
        </div>
        <div class="footer__copy">
          Copyright © 2022. All rights reserved.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import Scroll from "@/plugin/scroll";

export default {
  name: "Footer",

  computed: {
    ...mapGetters(['getMobile'])
  },

  mounted() {
    Scroll()
  }
}
</script>

<style scoped lang="scss">
  .container {
    justify-content: space-between;

    @media (max-width: 1024px) {
      flex-direction: column;
    }
  }

  .footer {
    width: 100%;
    height: auto;
    background-color: #2E2F34;
    padding: 65px 0;

    @media (max-width: 1024px) {
      padding: 30px 0;
    }

    &__col {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      @media (max-width: 1024px) {
        align-items: center;
      }
    }

    &__logo {
      @media (max-width: 1024px) {
        transform: scale(0.8);
      }
    }

    &__nav {
      display: flex;
      flex-direction: column;
    }

    &__navTitle {
      font-weight: 700;
      font-size: 18px;
      line-height: 20px;
      color: #FFFFFF;
      margin-bottom: 24px;
    }

    &__navList {
      display: flex;
      flex-direction: column;
      color: #ffffff;

      ul {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        li {
          margin-left: 0;
          margin-bottom: 30px;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }

    &__navItem {
      font-weight: 700;
      font-size: 14px;
      line-height: 16px;
      color: #FFFFFF;

      &:hover {
        color: transparent;
        background-clip: text;
        background-image: linear-gradient(99.05deg, #C824EC 0.07%, #DF308D 106.32%);
      }
    }

    &__contacts {
      display: flex;
      flex-direction: column;

      @media (max-width: 1024px) {
        margin: 10px 0 30px 0;
      }
    }

    &__contactsTitle {
      font-weight: 700;
      font-size: 18px;
      line-height: 20px;
      color: #FFFFFF;
      margin-bottom: 24px;
    }

    &__contactsText {
      font-weight: 500;
      font-size: 14px;
      line-height: 173.5%;
      color: #FFFFFF;

      @media (max-width: 1024px) {
        font-size: 18px;
        line-height: 22px;
        text-align: center;
      }

      @media (max-width: 767px) {
        font-size: 14px;
        line-height: 173.5%;
      }
    }

    &__social {
      display: flex;
      justify-content: space-between;
    }

    &__socialItem {
      width: 27px;
      height: 27px;
      border-radius: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;

      @media (max-width: 1024px) {
        width: 57px;
        height: 57px;
        margin: 0 10px 30px 10px;
      }

      @media (max-width: 767px) {
        width: 27px;
        height: 27px;
        margin: 0 5px 30px 5px;
      }
    }

    &__icon {
      fill: #2E2F34;
    }

    &__copy {
      font-weight: 400;
      font-size: 10px;
      line-height: 11px;
      display: flex;
      align-items: center;
      color: #FFFFFF;

      @media (max-width: 1024px) {
        font-size: 14px;
        line-height: 16px;
      }

      @media (max-width: 767px) {
        font-size: 10px;
        line-height: 11px;
      }
    }
  }

  .twitter {
    background-image: url("../assets/twitter.svg");

    &:hover {
      background-image: url("../assets/twitter-active.svg");
    }
  }

  .facebook {
    background-image: url("../assets/facebook.svg");

    &:hover {
      background-image: url("../assets/facebook-active.svg");
    }
  }

  .pinterest {
    background-image: url("../assets/pinterest.svg");

    &:hover {
      background-image: url("../assets/pinterest-active.svg");
    }
  }

  .instagram {
    background-image: url("../assets/instagram.svg");

    &:hover {
      background-image: url("../assets/instagram-active.svg");
    }
  }
</style>