<template>
  <div class="about" id="about">
    <div class="container">
      <div v-if="!getMobile" class="about__block about__image">
        <img src="../assets/about.png" alt="">
      </div>
      <div class="about__block">
        <div class="about__title orbitron">
          About out company
        </div>
        <div class="about__text spartan">
          <p>Since our foundation we were specialized in VFX, character creation, research and development of new technologies in the visual effects industry.</p>
          <p>We are committed to helping our clients realize their vision through closely collaborative relationships.</p>
        </div>
        <div v-if="getMobile" class="about__block about__image about__imageMobile">
          <img src="../assets/about.png" alt="">
        </div>
        <div class="about__description spartan">
          <span>Combining art, talent and creativity of people</span>, we work every day to create an excellent and inspiring result
        </div>
      </div>
    </div>
    <img class="about__cloud" src="../assets/cloud.png">
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "About",

  computed: {
    ...mapGetters(['getMobile'])
  }
}
</script>

<style scoped lang="scss">
  .container {
    @media (max-width: 1024px) {
      flex-direction: column;
    }
  }

  .about {
    position: relative;
    padding-bottom: 50px;

    &__cloud {
      position: absolute;
      right: 0;
      bottom: 50px;
    }

    &__block {
      display: flex;
      flex-direction: column;
      width: 50%;
      height: 100%;
      padding-top: 87px;

      @media (max-width: 1024px) {
        padding-top: 0;
        width: 100%;
      }
    }

    &__image {
      margin-top: 100px;

      @media (max-width: 1024px) {
        margin-top: 0;
      }
    }

    &__title {
      font-weight: 900;
      font-size: 36px;
      line-height: 46px;
      color: #FFFFFF;

      @media (max-width: 1024px) {
        text-align: center;
      }

      @media (max-width: 767px) {
        font-size: 24px;
        line-height: 30px;
      }
    }

    &__text {
      font-weight: 500;
      font-size: 18px;
      line-height: 28px;
      color: #FFFFFF;
      margin-top: 40px;

      @media (max-width: 1024px) {
        margin-top: 7px;
      }

      @media (max-width: 767px) {
        font-size: 18px;
        line-height: 28px;
      }
    }

    &__imageMobile {
      transform: scale(0.7);
    }

    &__description {
      font-weight: 500;
      font-size: 24px;
      line-height: 34px;
      color: #C54A92;
      margin-top: 30px;
      padding: 50px 78px;
      border: 1px solid #FFFFFF;
      border-radius: 5px;
      position: relative;

      @media (max-width: 1024px) {
        font-size: 20px;
        line-height: 24px;
        padding: 30px 45px;
        margin-top: 0;
      }

      @media (max-width: 767px) {
        font-size: 24px;
        line-height: 34px;
        padding: 30px 20px;
      }

      &::after {
        position: absolute;
        content: '';
        left: -20px;
        top: 40px;
        border: 1px solid #ffffff;
        border-right: none;
        border-bottom: none;
        width: 40px;
        height: 40px;
        transform: rotate(-45deg);
        z-index: 2;
        background-color: #000000;

        @media (max-width: 1024px) {
          display: none;
        }
      }

      span {
        font-weight: 700;
      }
    }
  }
</style>