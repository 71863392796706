<template>
  <div id="app">
    <Header />
    <Main />
    <About />
    <Services />
    <Footer />
  </div>
</template>

<script>
import Header from "./components/Header";
import Main from "@/components/Main";
import About from "@/components/About";
import Services from "@/components/Services";
import Footer from "@/components/Footer";

export default {
  name: 'App',
  components: {
    About,
    Main,
    Header,
    Services,
    Footer
  }
}
</script>

<style lang="scss">
  @import url('https://fonts.googleapis.com/css2?family=Orbitron:wght@500;900&display=swap');

  @font-face {
    font-family: 'SpartanMB-Medium';
    src: url('/assets/fonts/SpartanMB-Medium.otf') format('otf');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
  }

  body {
    margin: 0;
    padding: 0;

    &::-webkit-scrollbar {
      width: 10px;
      background-color: #f9f9fd;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background: linear-gradient(99.05deg, #C824EC 0.07%, #DF308D 106.32%);;
    }

    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.2);
      background-color: #000000;
    }
  }

  * {
    box-sizing: border-box;
    transition: 0.25s;
  }

  ul {
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
  }

  li {
    list-style: none;
    margin-left: 45px;
  }

  a {
    text-decoration: none;
  }

  #app {
    background: #020104;
  }

  .container {
    max-width: 1420px;
    margin: 0 auto;
    display: flex;
    width: 100%;
    height: 100%;

    @media (max-width: 1420px) {
      padding: 0 15px;
    }
  }

  .orbitron {
    font-family: 'Orbitron', sans-serif;
  }

  .spartan {
    font-family: 'SpartanMB-Medium', sans-serif;
  }
</style>
