<template>
  <input type="text" v-model="value" class="customInput spartan" :placeholder="placeholder" @input="$emit('input', $event.target.value)">
</template>

<script>
export default {
  name: "Input",

  props: {
    placeholder: String,
    value: String
  },
}
</script>

<style scoped lang="scss">
  .customInput {
    border: 1px solid #6952E5;
    border-radius: 5px;
    padding: 13px 0 13px 18px;
    width: 100%;
    height: auto;
    background-color: transparent;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    color: #FFFFFF;

    @media (max-width: 1024px) {
      margin-bottom: 10px;
    }

    &::placeholder {
      color: #FFFFFF;
    }

    &:focus {
      outline: #6952E5;
      border: 1px solid #df308d;
    }
  }
</style>