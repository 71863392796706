<template>
  <div class="header">
    <div class="container">
      <div class="header__logo">
        <a href="/">
          <img src="../assets/logo.png" alt="">
        </a>
      </div>
      <div v-if="!getMobile" class="header__nav">
        <ul>
          <li>
            <a class="spartan header__navItem" href="#about">About</a>
          </li>
          <li>
            <a class="spartan header__navItem" href="#services">Services</a>
          </li>
          <li>
            <a class="spartan header__navItem" href="#contact">Contact</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import Scroll from "@/plugin/scroll";

export default {
  name: "Header",

  computed: {
    ...mapGetters(['getMobile'])
  },
  mounted() {
    Scroll()
  }
}
</script>

<style scoped lang="scss">
  .header {
    padding-top: 50px;
    position: absolute;
    width: 100%;
    z-index: 2;

    @media (max-width: 767px) {
      padding-top: 10px;
    }

    .container {
      justify-content: space-between;

      @media (max-width: 767px) {
        justify-content: flex-start;
      }
    }

    &__logo {

      img {
        @media (max-width: 767px) {
          width: 150px;
        }
      }
    }

    &__nav {
      display: flex;
      align-items: center;
    }

    &__navItem {
      color: #ffffff;
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 16px;
      position: relative;

      //&--active {
      //  color: transparent;
      //  background-clip: text;
      //  background-image: linear-gradient(99.05deg, #C824EC 0.07%, #DF308D 106.32%);
      //  position: relative;
      //}

      &::after {
        position: absolute;
        content: '';
        width: 9px;
        height: 2px;
        background-image: linear-gradient(99.05deg, #C824EC 0.07%, #DF308D 106.32%);
        bottom: -10px;
        left: 40%;
        opacity: 0;
      }

      &:hover {
        color: transparent;
        background-clip: text;
        background-image: linear-gradient(99.05deg, #C824EC 0.07%, #DF308D 106.32%);

        &::after {
          opacity: 1;
        }
      }
    }
  }
</style>