<template>
  <div class="main">
    <div class="container">
      <div class="main__title orbitron">
        WE ARE GLOBAL VFX STUDIO
      </div>
      <div class="main__subtitle spartan">
        that creates stunning visuals and moving brand interation into new era
      </div>
      <div class="main__slider">
        <Slider />
      </div>
    </div>
  </div>
</template>

<script>
import Slider from "@/components/Main/Slider";
export default {
  name: "Main",

  components: {
    Slider
  }
}
</script>

<style scoped lang="scss">
  .main {
    width: 100%;
    height: 1250px;
    position: relative;
    background-image: url("../assets/main-bg.png");
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 767px) {
      margin-bottom: 60px;

      max-height: 960px;
      height: 100%;
    }

    &__title {
      font-weight: 900;
      font-size: 70px;
      line-height: 70px;
      text-align: center;
      color: #FFFFFF;
      padding: 0 300px;
      margin-top: 150px;

      @media (max-width: 1280px) {
        font-size: 60px;
        line-height: 60px;
        padding: 0 250px;
      }

      @media (max-width: 1024px) {
        font-size: 40px;
        line-height: 44px;
        margin-top: 50px;
      }

      @media (max-width: 767px) {
        padding: 0;
        margin-top: 70px;
        text-align: left;
      }
    }

    &__subtitle {
      font-style: normal;
      font-weight: 500;
      font-size: 36px;
      line-height: 40px;
      text-align: center;
      color: #C54A92;
      padding: 0 300px;
      margin-top: 35px;

      @media (max-width: 1280px) {
        font-size: 30px;
        line-height: 35px;
        padding: 0 250px;
      }

      @media (max-width: 1024px) {
        font-size: 24px;
        line-height: 30px;
      }

      @media (max-width: 767px) {
        padding: 0;
        text-align: left;
        margin-top: 10px;
      }
    }

    &__slider {
      width: 100%;
      margin-top: 46px;

      @media (max-width: 767px) {
        margin-top: 20px;
      }
    }
  }
  .container {
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
</style>