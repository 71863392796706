<template>
  <textarea :placeholder="placeholder" v-model="value" class="customTextarea spartan" @input="$emit('input', $event.target.value)"></textarea>
</template>

<script>
export default {
  name: "TextArea",

  props: {
    placeholder: String,
    value: String
  }
}
</script>

<style scoped lang="scss">
  .customTextarea {
    border: 1px solid #6952E5;
    border-radius: 5px;
    width: 100%;
    height: 100%;
    padding-top: 13px;
    padding-left: 18px;
    background-color: transparent;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    color: #FFFFFF;
    resize: none;

    &::placeholder {
      color: #FFFFFF;
    }

    &:focus {
      outline: #6952E5;
      border: 1px solid #df308d;
    }

    @media (max-width: 1024px) {
      height: 150px;
    }
  }
</style>