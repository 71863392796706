<template>
  <button class="customBtn spartan" @click="$emit('click', $event)">{{value}}</button>
</template>

<script>
export default {
  name: "Button",

  props: {
    value: String,
  },
}
</script>

<style scoped lang="scss">
  .customBtn {
    width: 100%;
    height: auto;
    background: #6952E5;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    font-weight: 500;
    font-size: 16px;
    line-height: 46px;
    color: #FFFFFF;
    border: none;
    cursor: pointer;

    &:hover {
      background: linear-gradient(99.05deg, #C824EC 0.07%, #DF308D 106.32%)
    }
  }
</style>