import Vue from 'vue'
import App from './App.vue'
import store from './store'
import { mapActions } from "vuex";

Vue.config.productionTip = false

new Vue({
  store,

  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
    if (window.innerWidth < 1024) {
      this.setMobile(true)
    } else {
      this.setMobile(false)
    }
  },

  methods: {
    ...mapActions(['setMobile']),

    onResize() {
      this.setMobile(window.innerWidth < 1024);
    },
  },

  render: function (h) { return h(App) }
}).$mount('#app')
