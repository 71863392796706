import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    slider: [
      {
        id: 0,
        image: 'VFX_breakdown.png',
        video: 'VFX_breakdown.mp4',
      },
      {
        id: 1,
        image: 'VFX_breakdown_3_.png',
        video: 'VFX_breakdown_3_.mp4',
      },
    ],
    sliderActive: 0,
    prevPage: null,
    mobile: false,
  },

  getters: {
    getSlider (state) {
      const buttonClicked = state.sliderActive
      const articlesOnPage = 1
      const startFrom = buttonClicked * articlesOnPage
      return state.slider.slice(startFrom, startFrom + articlesOnPage)
    },
    getSliderPagination (state) {
      return Math.ceil(state.slider.length)
    },
    getSliderActive (state) {
      return state.sliderActive
    },
    getMobile (state) {
      return state.mobile
    },
  },

  mutations: {
    setSliderActive (state, value) {
      state.sliderActive = value - 1
    },
    setNextPageSlider (state) {
      if (state.sliderActive !== Math.ceil(state.slider.length - 1)) {
        state.sliderActive = state.sliderActive + 1
      } else {
        state.sliderActive = 0
      }
    },
    setMobile (state, value) {
      state.mobile = value
    },
  },

  actions: {
    setSliderActive ({ commit }, value) {
      commit('setSliderActive', value)
    },
    setNextPageSlider ({ commit }) {
      commit('setNextPageSlider')
    },
    setMobile ({ commit }, value) {
      commit('setMobile', value)
    },
  },

})
